@import './vars';

body[data-section="contact"] {
  .colorful-section {
    animation: graychange 8s infinite;
    animation-direction: alternate;
  }
  .contact {
    &.inner {
      display: flex;
      flex-direction: column;
      .spacer {
        margin-top: auto;
        width: 100%;
      }
    }
    &-wrapper {
      max-width: 540px;
      margin: 0 auto;
      grid-template-columns: var(--grid-template-halves);
      grid-gap: var(--layout-margin-medium);
    }
    &-heading {
      width: 540px;
      margin: var(--layout-margin-medium) auto var(--layout-margin-medium);
      h2 {
        font-family: var(--font-Lato-Heavy);
        margin-bottom: var(--layout-margin-medium);
      }
      p {
        margin-bottom: var(--layout-margin-large);
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      &.mod-fullwidth {
        grid-column: 1 / -1;
      }
    }
    &-submit {
      margin-bottom: var(--layout-margin-large);
      .button {
        width: 100%;
      }
    }
  }

  @include breakpoint(small) {
    .contact {
      &-heading {
        margin-bottom: var(--layout-margin-small);
        h2 {
          margin-bottom: var(--layout-margin-medium);
        }
        p {
          margin-bottom: var(--layout-margin-small);
        }
      }
      &-wrapper {
        grid-gap: var(--layout-margin-small);
      }
    }
  }

  @include breakpoint(medium) {
    .contact-heading h2 {
      font-size: var(--font-size-4);
    }
  }
}
