@import './vars';

.header a {
  font-family: var(--font-Lato-Medium);
}

@include breakpoint(small) {
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--layout-padding-small) var(--layout-margin-medium);
    &-nav,
    &-right-link {
      display: none;
    }
  }
}

@include breakpoint(mediumlarge) {
  .header {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-Lato-Medium);
    &-nav {
      ul.header-links {
        margin: 0;
      }
      li.header-link {
        display: inline-block;
        margin: var(--layout-margin-small);
      }
    }
    &-right-link {
      margin: var(--layout-margin-small) 0;
    }
    .button-menu {
      display: none;
    }
    a {
      color: var(--color-gray-darkest);
      text-decoration: none;
    }
  }
}

@include breakpoint(medium) {
  .header {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: var(--layout-padding-small) var(--layout-padding-medium);
  }
}

@include breakpoint(large) {
  .header {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--layout-padding-small);
  }
}