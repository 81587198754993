@import 'vars';
@import 'layout';

body[data-section="landing"] {
  background-color: var(--color-gray-light);

  .colorful-section {
    animation: colorchange 20s infinite;
    animation-direction: alternate;
  }
  .landing {
    &-cta-secondary {
      a {
        font-family: var(--font-Lato-Medium);
      }
    }
    &-section-2 {
      background-color: var(--color-gray-light);
      .timeline {
        &-points {
          > div {
            &::before {
              position: relative;
              z-index: 1;
              content: '';
              display: block;
              border-radius: 20px;
              background-color: var(--color-gray-darkest);
            }
          }
          .oblique {
            color: var(--color-gray-medium);
          }
        }
      }
    }
    &-section-3 {
      position: relative;
      .slant {
        position: relative;
        .lower-cta {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &-content {
        position: relative;
      }
      .about-social-button {
        transition-duration: var(--transition-href-timing);
        opacity: .8;
        &:hover {
          opacity: 1;
          span {
            text-decoration: underline;
          }
        }
      }
      .about-social span {
        font-family: var(--font-Lato-Medium);
      }
    }
  }

  @include breakpoint(small) {
    .skewed-bg-top {
      height: calc(var(--layout-skew-top-offset) + 300px);
    }
    .skewed-bg-bottom {
      height: calc(var(--layout-skew-top-offset) + 266px);
    }
    .nav-wrapper {
      position: relative;
      z-index: 5;
    }
    .landing {
      &-section-1 {
        padding-bottom: var(--layout-padding-large);
        > div {
          &:first-child {
            margin-top: var(--layout-margin-large);
          }
          &:last-child {
            display: none;
          }
        }
        &-content {
          padding-bottom: var(--layout-padding-small);
        }
        &-offset {
          margin-top: -500px;
        }
        &-correction {
          position: relative;
          top: 500px;
        }
      }
      &-cta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: var(--layout-margin-medium);
        &-secondary {
          margin-top: var(--layout-margin-small);
        }
      }
      &-section-2 {
        margin-top: 440px;
        .timeline {
          position: relative;
          margin-top: var(--layout-margin-medium);
          &-gradients {
            position: absolute;
            top: 12px;
            width: 2px;
            left: 6px;
            height: 120%;
            background: linear-gradient(180deg, rgba(128,255,0,1) 0%, rgba(0,255,64,1) 13%, rgba(0,255,255,1) 26%, rgba(0,64,255,1) 39%, rgba(128,0,255,1) 51%, rgba(255,0,191,1) 66%, rgba(255,0,0,1) 80%, rgba(255,191,0,1) 93%);
          }
          &-points {
            > div {
              display: flex;
              margin-bottom: var(--layout-margin-medium);
              p {
                margin: 0 0 var(--layout-margin-small) var(--layout-margin-small);
                width: auto;
              }
              &::before {
                flex: 0 0 15px;
                height: 15px;
                width: 15px;
                transform: translateY(7px);
              }
            }
          }
        }
      }
      &-section-3 {
        .inner {
          padding-top: var(--layout-padding-large);
        }
        .about-social {
          display: flex;
          text-align: center;
          margin: var(--layout-margin-medium) 0;
          > div {
            width: 69%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            a {
              margin-bottom: var(--layout-margin-small);
              img {
                width: 27px;
                margin-right: var(--layout-margin-xsmall);
              }
            }
          }
          > img {
            margin: 0 auto;
            transform: translateY(-14px);
          }
        }
      }
    }
  }

  @include breakpoint(medium) {
    .landing {
      &-section-1 {
        > div {
          &:last-child {
            margin-top: var(--layout-margin-medium);
          }
        }
      }
      &-section-2 {
        overflow-x: hidden;
        h2 {
          text-align: center;
        }
      }
      &-section-3 {
        .inner {
          padding-top: var(--layout-margin-large);
          > div:last-of-type {
            margin-right: 0;
            margin-top: var(--layout-margin-xlarge);
          }
        }
        .about-social-button {
          font-size: var(--font-size-0);
          text-transform: uppercase;
        }
      }
      &-cta {
        button {
          margin: var(--layout-margin-small) var(--layout-margin-medium) var(--layout-margin-small) 0;
        }
        &-secondary {
          margin-top: var(--layout-margin-small);
        }
      }
    }
  }

  @include breakpoint(large) {
    .landing {
      &-section-1 {
        padding-bottom: var(--layout-margin-xlarge);
        > div {
          &:last-child {
            margin-top: var(--layout-margin-large);
          }
        }
      }
      &-section-2 {
        overflow-x: hidden;
      }
      &-section-3 {
        .inner {
          padding: var(--layout-padding-large) var(--layout-padding-small);
          > div:last-of-type {
            margin-top: var(--layout-margin-medium);
          }
        }
      }
      &-cta {
        display: flex;
        align-items: center;
        margin-top: var(--layout-margin-medium);
        &-secondary {
          margin-left: var(--layout-margin-medium);
        }
      }
    }
  }

  @include breakpoint(mediumlarge) {
    .landing {
      &-section-1 {
        > div {
          &:first-child {
            margin-top: var(--layout-margin-xxlarge);
          }
        }
        &-offset {
          margin-top: -530px;
        }
        &-correction {
          position: relative;
          top: 530px;
        }
      }
      &-section-2 {
        margin-top: 420px;
        h2 {
          text-align: center;
        }
        .timeline {
          margin: var(--layout-margin-large) 0 0;
          position: relative;
          &-gradients {
            position: absolute;
            z-index: 0;
            left: var(--layout-margin-small);
            top: 9px;
            height: 2px;
            width: 160%;

            background-color: black;
            background: linear-gradient(90deg, rgba(128,255,0,1) 0%, rgba(0,255,64,1) 10%, rgba(0,255,255,1) 23%, rgba(0,64,255,1) 35%, rgba(128,0,255,1) 45%, rgba(255,0,191,1) 55%, rgba(255,0,0,1) 62%, rgba(255,191,0,1) 70%, rgba(251,251,251,0) 80%, rgba(251,251,251,1) 90%);
          }
          &-points {
            grid-template-columns: var(--grid-template-columns);
            > div {
              &::before {
                height: 20px;
                width: 20px;
                margin-bottom: var(--layout-margin-medium);
              }
            }
          }
        }
      }
      &-section-3 {
        .inner {
          grid-template-columns: var(--grid-template-halves);
          > * {
            margin-right: var(--layout-margin-large);
          }
          > div:last-of-type {
            text-align: center;
          }
          p {
            margin-bottom: var(--layout-margin-small);
          }
          .about-social {
            > div {
              display: flex;
              width: 100%;
              margin: var(--layout-margin-medium) 0 var(--layout-margin-medium);
            }
            &-button {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              span {
                display: inline-block;
                margin-top: var(--layout-padding-xsmall);
              }
              &:nth-child(2) {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

