@import './vars';

.signup {
  &-spinner {
    transform: scale(.42);
    left: -10px;
    top: -17px;
  }
  &-button {
    height: 45px;
    background-color: var(--color-gray-darkest);
    color: var(--color-white);
    border-radius: 3px;
    font-family: var(--font-Lato-Medium);
    font-size: var(--font-size-1);
    border-radius: 100px;
  }
}

@include breakpoint(small) {
  .signup {
    &-email {
      width: 100%;
      height: 45px;
      border: none;
      margin-top: var(--layout-margin-small);
    }
    &-button {
      width: 100%;
      margin: var(--layout-margin-small) 0 var(--layout-margin-medium);
    }
  }
}

@include breakpoint(medium) {
  .signup {
    &-email,
    &-button {
      width: 100%;
      height: 45px;
    }
    &-email {
      border: none;
    }
    &-button {
      margin-top: var(--layout-margin-small);
    }
  }
}

@include breakpoint(large) {
  .signup {
    &-email {
      width: 75%;
      height: 45px;
      border: none;
    }
    &-button {
      margin-left: 5%;
      width: 20%;
    }
  }
}