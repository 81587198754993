@import 'vars';

@mixin span-columns($start, $end) {
  grid-column-start: $start;
  grid-column-end: $end;
}

.grid {
  display: grid;
}

@include breakpoint(small) {
  :root {
    --grid-template-columns: auto;
  }

  .outer {
    position: relative;
    width: 100%;
    top: 0;
    transition-duration: var(--transition-menu);
    transition-timing-function: var(--easing-menu);
    &.mod-menu-open-content {
      top: 100vh;
    }
  }
  .inner {
    padding: 0 var(--layout-padding-medium);
  }
  .menu-mobile {
    position: absolute;
    top: -100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-image: url('../images/hs.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(255, 255, 255, .5);
    &-overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: var(--layout-padding-medium);
      position: absolute;
      opacity: .8;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: var(--layout-margin-medium);
    }
    &-logo {
      width: 15vh;
      margin-top: var(--layout-margin-medium);
    }
    &-app-store {
      margin-top: var(--layout-margin-medium);
    }
    &-links {
      margin-top: var(--layout-margin-medium);
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 250px;
      border-radius: 50px;
      font-size: var(--font-size-2);
      font-family: var(--font-Lato-Medium);
    }
  }
  .page-wrapper {
    overflow: hidden;
  }
  @include breakpoint(smallheight) {
    .menu-mobile {
      &-links {
        margin-top: var(--layout-margin-small);
      }
      &-link {
        font-size: calc(var(--font-size-2) * .9);
        height: 35px;
      }
      &-app-store {
      margin-top: var(--layout-margin-small);
    }
    }
  }
}

@include breakpoint(medium) {
  :root {
    --grid-template-columns: auto auto auto auto;
    --grid-template-half-two: 2fr 1fr 1fr;
    --grid-template-halves: 2fr;
    --grid-template-full: 1fr;
  }
  .outer {
    width: 100%;
  }
  .inner {
    padding: var(--layout-padding-medium);
  }
}

@include breakpoint(large) {
  .outer {
    width: 100%;
  }
  .inner {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--layout-padding-small);
  }
}

@include breakpoint(mediumlarge) {
  :root {
    --grid-template-columns: auto auto auto auto;
    --grid-template-halves: 1fr 1fr;
    --grid-template-half-two: 2fr 1fr 1fr;
  }
  .menu-mobile {
    display: none;
  }
  .grid-halves {
    grid-template-columns: var(--grid-template-halves);
  }
  .grid-full {
    grid-template-columns: var(--grid-template-full);
  }
  .grid {
    &.mod-grid-spaced {
      > * {
        margin-right: var(--layout-margin-large);
      }
    }
  }
}


